import React from 'react'

import { FaFacebookF, FaGithub, FaInstagram, FaDiscord, FaReddit, FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import trust from '../media/images/trust.png'
import badge from '../media/images/trust-wallet.png'

import apple from '../media/images/apple.png'
import chrome from '../media/images/chrome.png'
import apk from '../media/images/apk.png'
import google from '../media/images/google-play.png'


const Footer = () => {
    return (
        <footer>
            <div className="footer-wrapper">
                <div className="footer-logo">
                    <div className="image-container">
                        <img className='img-1' src={trust} alt="TRUST" />
                        <img className='img-2' src={badge} alt="Trust Badge" />
                    </div>
                </div>
                <div className="footer-site-map">
                    <nav>
                        <ul>
                            <h3>Wallet</h3>
                            <li>
                                <a href="https://trustwallet.com/download">Mobile App</a>
                            </li>
                            <li>
                                <a href="https://trustwallet.com/browser-extension">Browser <br/>Extension</a>
                            </li>
                        </ul>
                        <ul>
                            <h3>Features</h3>
                            <li>
                                <a href="https://trustwallet.com/buy-crypto">Buy Crypto</a>
                            </li>
                            <li>
                                <a href="https://trustwallet.com/swap">Swap</a>
                            </li>
                            <li>
                                <a href="https://trustwallet.com/staking">Staking</a>
                            </li>
                            <li>
                                <a href="https://trustwallet.com/nft">NFTs</a>
                            </li>
                            <li>
                                <a href="https://trustwallet.com/security">Security</a>
                            </li>
                        </ul>
                        <ul>
                            <h3>Build</h3>
                            <li>
                                <a href="https://developer.trustwallet.com/developer/">Developer Docs</a>
                            </li>
                            <li>
                                <a href="https://developer.trustwallet.com/developer/wallet-core">Wallet Core</a>
                            </li>
                            <li>
                                <a href="https://developer.trustwallet.com/developer/listing-new-dapps">Submit dApp</a>
                            </li>
                            <li>
                                <a href="https://developer.trustwallet.com/developer/listing-new-assets">Get assets listed</a>
                            </li>
                        </ul>
                        <ul>
                            <h3>Support</h3>
                            <li>
                                <a href="https://community.trustwallet.com/c/helpcenter/8">FAQ</a>
                            </li>
                            <li>
                                <a href="https://community.trustwallet.com/">Community <br/>Forum</a>
                            </li>
                            <li>
                                <a href="https://support.trustwallet.com/en/support/home">Contact Us</a>
                            </li>
                        </ul>
                        <ul>
                            <h3>About</h3>
                            <li>
                                <a href="https://trustwallet.com/about-us">About Us</a>
                            </li>
                            <li>
                                <a href="https://trustwallet.com/careers">Career</a>
                            </li>
                            <li>
                                <a href="https://trustwallet.com/press">Press Kit</a>
                            </li>
                            <li>
                                <a href="https://trustwallet.com/terms-of-service">Terms of Service</a>
                            </li>
                            <li>
                                <a href="https://trustwallet.com/privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="https://trustwallet.com/blog">Blog</a>
                            </li>
                        </ul>
                    </nav>
                    <div className="access-panel">
                        <h3>Download Trust Wallet</h3>
                        <p>The most trusted and secure crypto wallet.</p>
                        <div className="link-box">
                            <div className="download-links">
                                <div className="link-row">
                                    <a href="https://apps.apple.com/app/apple-store/id1288339409?mt=8" target='_blank'>
                                        <button className="btn disconnected">
                                            <span>Download for iOs</span>
                                            <img src={apple} alt="App Store" />
                                        </button>
                                    </a>
                                    <a href="https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph" target='_blank'>
                                        <button className="btn disconnected">
                                            <span>Download Extension</span>
                                            <img src={chrome} alt="Chrome Extension" />
                                        </button>
                                    </a>
                                </div>
                                <div className="link-row">
                                    <a href="https://trustwallet.com/download/apk" target='_blank'>
                                        <button className="btn disconnected">
                                            <span>Download APK</span>
                                            <img src={apk} alt="APK" />
                                        </button>
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&referrer=utm_source%3Dwebsite" target='_blank'>
                                        <button className="btn disconnected">
                                            <span>Download for Android</span>
                                            <img src={google} alt="Google Play" />
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div className="social-links">
                                <h1>Stay Connected:</h1>
                                <ul className='social-icons'>
                                    <li>
                                        <a target="_blank" href="https://facebook.com/trustwalletapp"><FaFacebookF /></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://github.com/trustwallet"><FaGithub /></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://instagram.com/trustwallet"><FaInstagram /></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://twitter.com/trustwallet"><FaXTwitter /></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://discord.gg/trustwallet"><FaDiscord /></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://reddit.com/r/trustapp"><FaReddit /></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://t.me/trustwallet"><FaTelegramPlane /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
