import React from 'react'

import found from '../media/images/found.png'
import stopping from '../media/images/stopping.png'
import trusted from '../media/images/trusted.png'

const Empower = () => {
    return (
        <div className='empower'>
            <div className="empower-container">
                <h1>Join us as we empower millions through Trust</h1>
                <div className="montage-case">
                    <div className="montage">
                        <div className="montage-row">
                            <div className="montage-text">
                                <h1>Founded 2017</h1>
                                <p>
                                    Our journey started with a clear goal: to simplify and democratize crypto, making it accessible to everyone.
                                </p>
                            </div>
                            <div className="montage-icon">
                                <img src={found} alt="" />
                            </div>
                        </div>
                        <div className="montage-row">
                            <div className="montage-icon">
                                <img src={trusted} alt="" />
                            </div>
                            <div className="montage-text">
                                <h1>Trusted by 70M+ people</h1>
                                <p>
                                    We’ve built the trust of millions by making Web3 safer, easier, and more seamless to access.
                                </p>
                            </div>
                        </div>
                        <div className="montage-row">
                            <div className="montage-text">
                                <h1>We’re not stopping there</h1>
                                <p>
                                    We’re laying the foundation to bring more people than ever safely on-chain.
                                </p>
                            </div>
                            <div className="montage-icon">
                                <img src={stopping} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Empower
