import React from 'react'
import { useStateValue } from '../context/StateProvider'
import { MdOutlineClose } from "react-icons/md";
import { VscDebugDisconnect } from "react-icons/vsc";
import CheckEligibility from './CheckEligibility';


const ConnectOptions = () => {
    const [{ showWalletOptions }, dispatch] = useStateValue()
    function disconnectWallet() {
        dispatch({ type: 'CLEAR_WALLET' })
        dispatch({ type: 'TOGGLE_SHOW_WALLET_OPTIONS', option: null })
        dispatch({ type: 'SET_NOTIFICATION', data: { type: 'warn', message: 'Disconnected!'}})
        dispatch({ type: 'TOGGLE_CONTENT', content: {component: <CheckEligibility />, title: 'check-eligibility'} })
    }

    return (
        showWalletOptions ? (
            <div className="wallet-option-container">
                <div className='wallet-options'>
                    <MdOutlineClose className='spinner' onClick={() => dispatch({ type: 'TOGGLE_SHOW_WALLET_OPTIONS', option: null })} />
                    <h3>Disconnect Wallet</h3>
                    <div className="disclaimer">
                        You are about to disconnect your wallet from our DApp. Click the button below to proceed.
                    </div>
                    <button className='btn disconnect' onClick={disconnectWallet}><VscDebugDisconnect /> Disconnect</button>
                </div>
            </div>
        ) : null
    )
}

export default ConnectOptions
