import React, { useEffect, useState } from 'react'
import { formatUnits } from 'ethers';
import { useStateValue } from '../context/StateProvider'
import { connectWallet, initContract } from '../utils'

import { GrValidate } from "react-icons/gr";
import { SlActionRedo } from "react-icons/sl";
import largeIcon from '../media/images/logo.png'
import WalletManager from './WalletManager';
import ClaimAirdrop from './ClaimAirdrop';
import Reserve from './Reserve';
import ClaimsCount from './ClaimsCount';
import Referrals from './Referrals';


const NotEligible = () => {
    const [{ tokenName, minTokenBalanceRequired }, dispatch] = useStateValue()

    return (
        <div className="modal-content">
            <div className="modal-text">
                <h1>Oops!</h1>
                <p>Sorry you are not eligible for this airdrop. You need to hold a minimum of <span className="highlight">{minTokenBalanceRequired} {tokenName}s</span> to become eligible for this airdrop.</p>
            </div>
            <div className="modal-action">
                <button 
                    className="btn"
                    onClick={() => dispatch({ type: 'SET_MODAL', data: null})}>
                    OK
                </button>
            </div>
        </div>
    )
}

const ModalContent = () => {
    const [{ 
        abi, 
        allowedChainIds, 
        minTokenBalanceRequired, 
        networkTitle, 
        contractAddress, 
        airDropToken, 
        tokenName,
        airTokenName, 
        tokenAbi, 
        wallet, 
        provider,
        totalAirdropAmount,
        endDate
    }, dispatch] = useStateValue()
    const [hasAgreed, setHasAgreed] = useState(false)
    const [disabled, setDisabled] = useState(true)

    const handleCheck = () => setHasAgreed(!hasAgreed)
    useEffect(() => {
        async function initialCheck() {
            try {
                await checkNetwork()
            } catch(err) {
                dispatch({
                    type: 'SET_NOTIFICATION',
                    data: {
                        type: 'error',
                        message: err.reason || err.message || JSON.stringify(err)
                    }
                })
                dispatch({
                    type: 'SET_MODAL',
                    data: null
                })
            }
        }
        initialCheck()
    }, [])
    useEffect(() => {
        if (hasAgreed === true) 
            setDisabled(false)
        else
            setDisabled(true)
    }, [hasAgreed])
    async function checkNetwork() {
        return new Promise(async (resolve, reject) => {
            try {
                let connectedProvider
                if (provider === null) {
                    const {provider} = await connectWallet()
                    connectedProvider = provider
                }else {
                    connectedProvider = provider
                }
                const network = await connectedProvider.getNetwork()
                const chainId = network.chainId
                console.log(chainId)
                if (!allowedChainIds.includes(chainId)) {
                    return reject({message: `connected to the wrong network or chain. You should be connected to ${networkTitle}`})
                }
                return resolve()
            } catch(err) {
                return reject(err)   
            }
        })
    }
    async function check() {
        dispatch({
            type: 'SET_MODAL',
            data: null
        })
        dispatch({
            type: 'TOGGLE_LOADER'
        })
        try {
            const {provider, signer, address} = await connectWallet()
            if (wallet === null) {
                dispatch({
                    type: 'SET_WALLET',
                    address
                })
            }
            const contract = await initContract(contractAddress, abi, signer)
            const tokenContract = await initContract(airDropToken, tokenAbi, signer)
            let balance = await tokenContract.balanceOf(address)
            let minAllowedValue = await contract.minAllowedValue()
            const isEligible = parseFloat(formatUnits(balance)) >= parseFloat(formatUnits(minAllowedValue))

            if (isEligible && isEligible != null) {
                dispatch({ type: 'SET_CONTRACT', provider, contract, signer, tokenContract })
                dispatch({ type: 'TOGGLE_CONTENT', content: {component: <ClaimAirdrop />, title: 'claim-airdrop'}, balance: formatUnits(balance) })
            }else {
                dispatch({
                    type: 'SET_MODAL',
                    data: {
                        content: <NotEligible />
                    }
                })
            }
        } catch (err) {
            dispatch({
                type: 'SET_NOTIFICATION',
                data: {
                    type: 'error',
                    message: err.reason || err.message || JSON.stringify(err)
                }
            })
        }finally {
            dispatch({
                type: 'TOGGLE_LOADER'
            })
        }
    }
    return (
        <div className="modal-content">
            <div className="modal-text">
                Before proceeding to check your eligibility to claim this airdrop, you need to put in mind that this airdrop is aimed at rewarding loyal community members for <span className="highlight">HODLing</span> this season. <span className="highlight">{totalAirdropAmount} {airTokenName}</span> to be claimed until the <span className="highlight">{endDate}</span> . To proceed, you first need to have a minium of <span className="highlight">{minTokenBalanceRequired} {tokenName}s</span> in your wallet.
            </div>
            <div className="modal-action">
                <div className="disclaimer">
                    <label htmlFor="agree-checkbox">
                        <input 
                            type="checkbox" 
                            name='agree-checkbox' 
                            id='agree-checkbox' 
                            checked={hasAgreed} 
                            onChange={handleCheck} /> 
                            I have read and I agree to the terms and condition for claiming this airdrop.
                    </label>
                </div>
                {
                    wallet ? (<button 
                                className={disabled === false ? 'btn' : 'btn disabled'}
                                disabled={disabled} 
                                onClick={check}><SlActionRedo /> Continue</button>) : (
                        <>
                            <div className="disclaimer">
                                <span>Your wallet is not connected. Connect to proceed.</span>
                            </div>
                            <WalletManager />
                        </>
                    )
                }
                <div className="cancel" onClick={() => dispatch({ type: 'SET_MODAL', data: null})}>cancel</div>
            </div>
        </div>
    )
}

const CheckEligibility = () => {
    const [{wallet, tokenName, promoTitle}, dispatch] = useStateValue()
    
    function checkMessage() {
        dispatch({
            type: 'SET_MODAL',
            data: {
                content: <ModalContent />
            }
        })
    }
    return (
        <div className='content check-eligibility'>
            <div className="container">
                <div className="row eligibility-dash">
                    <div className="description">
                        <div className="dash-note">
                            <h1>Join {promoTitle} <span className=''>{tokenName}</span> Airdrop Campaign and get up to <span className="promo">10<sup>%</sup></span> of <span className="">your bag</span> for <span className=''>Participating</span></h1>
                        </div>
                        <div className="call-to-action">
                            <button
                                className="btn"
                                onClick={checkMessage}>
                                <GrValidate /> Check Eligibility
                            </button>
                        </div>
                        <div className="call-to-action">
                            <ClaimsCount />
                            <Reserve />
                        </div>
                    </div>
                    <div className="large-icon">
                        <img src={largeIcon} alt="" />
                    </div>
                </div>
                {
                    wallet != null ? <Referrals /> : wallet
                }
            </div>
        </div>
    )
}

export default CheckEligibility
