import React from 'react'

import loader from '../media/images/logo.png'
import { useStateValue } from '../context/StateProvider'

const Loader = () => {
    const [{ loading }] = useStateValue()
    return (
        loading ? (
            <div className='loader'>
                <div className="loader-content">
                    <img src={loader} alt="Processing..." />
                    <font>Please wait...</font>
                </div>
            </div>
        ) : null
    )
}

export default Loader
