import React from 'react'
import { useStateValue } from '../context/StateProvider'
import { FaPeopleGroup } from "react-icons/fa6";

const ClaimsCount = () => {
    const [{claimsCounts}] = useStateValue()
    return (
        claimsCounts ?
        <div className='x2-container'>
            <div className="x2-box">
                <h3>Airdrop Claims</h3>
                <div className="x2-text-icon-content">
                    <font>{parseFloat(claimsCounts).toLocaleString()}</font>
                    <FaPeopleGroup />
                </div>
            </div>
        </div>
        : null
    )
}

export default ClaimsCount
