import React from 'react'

import spinner from '../media/images/spinner.gif'

const ContentLoader = ({spinnerHeight = 30, spinnerWidth = 'auto'}) => {
    return (
        <div>
            <img src={spinner} alt="loading..." height={spinnerHeight} />
        </div>
    )
}

export default ContentLoader
