import React from 'react'

import link_break from '../media/images/link-break.png'

const BrokenComponent = () => {

    const reloadPage = () => {
        return window.location.reload()
    }
    return (
        <div className='broken-component'>
            <div className="error-screen">
                <h1>503</h1>
                <img src={link_break} alt="Broken" />
                <div className="error-message">
                    Oops! An error occurred that broke the app. Please refresh the page and try again.
                </div>
                <button 
                    className="btn refresh"
                    onClick={reloadPage}>
                    Refresh
                </button>
            </div>
        </div>
    )
}

export default BrokenComponent