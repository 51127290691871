import React, { useState, useEffect } from 'react'
import { useStateValue } from '../context/StateProvider';
import CheckEligibility from './CheckEligibility';
import { useLocation } from 'react-router-dom';
import Empower from './Empower';

const Content = () => {
    const [{ content }, dispatch] = useStateValue();
    const [contentDisplay, setContentDisplay] = useState(<CheckEligibility />);


    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const ref = queryParams.get('ref')

    if (ref != null)
        dispatch({
            type: 'SET_REFERRER',
            referrer: ref
        })

    useEffect(() => {
        console.log(content)
        if (content != null)
            setContentDisplay(content.component)
    }, [content])
    return (
        <main>
            <div className='content-container'>
                {contentDisplay}
            </div>
            <div className="floater"></div>
            {content?.title === 'check-eligibility' || content?.title == null ? <Empower /> : null}
        </main>
    )
}

export default Content
