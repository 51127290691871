import React from 'react'
import { useStateValue } from '../context/StateProvider'

const Modal = () => {
    const [{ modal }] = useStateValue()
    return (
        modal != null ? (
            <div className='modal-container'>
                <div className="modal-box">
                    {modal?.content}
                </div>
            </div>
        ) : null
    )
}

export default Modal
