
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers'

const { BrowserProvider, getDefaultProvider, Contract } = require('ethers');

export function connectWallet() {
    return new Promise(async (resolve, reject) => {
        if (typeof window.ethereum !== 'undefined') {
            try {
                const provider = new BrowserProvider(window.ethereum)
                const signer = await provider.getSigner()
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const accounts = await provider.listAccounts();
                return resolve({provider, signer, address: accounts[0].address})
            } catch (error) {
                if (
                    error.name === 'UserRejectedRequestError' ||
                    error.code === 4001 ||
                    error.message === 'User rejected the request.'
                ) return reject(error)
                try {
                    const provider = getDefaultProvider();
                    const signer = provider.getSigner()
                    await window.ethereum.request({ method: 'eth_requestAccounts' });
                    const accounts = await provider.listAccounts();
                    return resolve({provider, signer, address: accounts[0].address})
                } catch (err) {
                    return reject(err)
                }
            }
        } else {
            try {
                const projectId = '4515816ba05f8307e02c62177bef34cd'
                const mainnet = {
                    chainId: 137,
                    name: 'Polygon',
                    currency: 'MATIC',
                    explorerUrl: 'https://polygonscan.com',
                    rpcUrl: 'https://polygon-rpc.com'
                }

                const metadata = {
                    name: 'PolyDoge Bingo2Earn',
                    description: 'Participate in the Bingo2Earn and earn free POLYDOGE tokens',
                    url: 'https://bingo.polydoge.online',
                    icons: ['https://bingo.polydoge.online/favicon.ico']
                }

                const modal = createWeb3Modal({
                    ethersConfig: defaultConfig({ metadata }),
                    chains: [mainnet],
                    projectId
                })
                modal.open()
            } catch(err) {
                return reject(err)
            }
        }
    })
}
export function initContract(address, abi, provider = null) {
    return new Promise(async (resolve, reject) => {
        try {
            if (provider === null) {
                provider = getDefaultProvider()
                provider = provider.getSigner()
            }
            const contract = new Contract(address, abi, provider)
            return resolve(contract)
        } catch(err) {
            return reject(err)
        }
    })
}