import React from 'react'
import { useStateValue } from '../context/StateProvider'
import { copyContent } from '../context/general';
import { FaRegCopy } from "react-icons/fa";
import { FaArrowsDownToPeople } from "react-icons/fa6";
import { GiCheckMark } from "react-icons/gi";

const domain = window.location.hostname

const Referrals = () => {
    const [{wallet, referrals, minReferralCount}, dispatch] = useStateValue()

    function copyToClipboard() {
        copyContent(`https://${domain}?ref=${wallet}`)
        dispatch({
            type: 'SET_NOTIFICATION',
            data: {
                type: 'success',
                message: 'copied!'
            }
        })
    }

    return (
        <div className="row referrer">
            <h1>Earn more when you tell a friend</h1>
            <h3>Your referral link:</h3>
            <p><span className="truncated">https://{domain}?ref={wallet}</span> <FaRegCopy onClick={copyToClipboard} /></p>
            <div className="referral-box">
                <div className='x2-container'>
                    <div className='x2-box'>
                        <h3>Referred Members</h3>
                        <div className="x2-text-icon-content">
                            <font>{referrals}</font>
                            {parseInt(referrals) >= parseInt(minReferralCount) ? <GiCheckMark className='success' /> : null}
                            <FaArrowsDownToPeople />
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    )
}

export default Referrals
